import { PaymentRegistryService } from '@/modules/payment-registry/payment-registry-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';
export const DO_SELECT = 'select';
export const DO_UNSELECT = 'unselect';

// mutation types
export const SET_SELECTED = 'setSelected';
export const REMOVE_SELECTED = 'removeSelected';
export const UPDATE_SUCCESS = 'updateSuccess';

const state = {
    record: null,
    openEditing: false,
};

const getters = {
    record: (state) => state.record,
    openEditing: (state) => state.openEditing,
};

const actions = {
    [DO_SELECT](context, record) {
        context.commit(SET_SELECTED, record);
    },
    [DO_UNSELECT](context) {
        context.commit(REMOVE_SELECTED);
    }
};

const mutations = {
    [SET_SELECTED](state, record) {
        state.record = record;
        state.openEditing = true;
    },
    [REMOVE_SELECTED](state) {
        state.record = {};
        state.openEditing = false;
    },
    [UPDATE_SUCCESS](state) {
        state.openEditing = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
