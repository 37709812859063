import paymentRegistryFormStore from '@/modules/payment-registry/store/payment-registry-form-store';
import paymentRegistryListStore from '@/modules/payment-registry/store/payment-registry-list-store';

export default {
    namespaced: true,

    modules: {
        form: paymentRegistryFormStore,
        list: paymentRegistryListStore,
    },
};
