import ApiService from '@/shared/services/api.service';

export class PaymentRegistryService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/payments/all', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static generateReport(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/staff-applications/generate-monthly-report', { ...params })
            .then(async (response) => {
                resolve(response)
            })
            .catch((error) => reject(error));
        })
    }
}
